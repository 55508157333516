// FIXME : ménage

.catalog-box {
  position: relative;
  background: #fff;
  border: solid transparent;
  height: 300px;
  width: 250px;
  margin: 15px;
  float: left;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  transition: ease all 0.25s;
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 20px;
    text-align: center; }
  // .view-details
  //   position: absolute
  //   bottom: 0
  //   left: 0
  //   right: 0
  //   margin: 0 40px 20px
  &:hover {
    border: solid #3498db; }
  .itemwrap {
    max-height: 119px;
    overflow: hidden;
    hr {
      margin: 10px!important; } }
  h5 {
    text-align: center;
    padding-top: 15px;
    margin: 0 15px;
    font-weight: 700; }
  // &.machine
  //   font-size: 12px
  //   height: 245px
  //   h5
  //     padding-top: 5px
  //   .copy-button-container
  //     height: 12px
  &.inactive {
    background-color: #e0e0e0;
    box-shadow: none;
    &:hover {
      border: solid transparent; }
    .catalog-icon {
      background-color: #e0e0e0;
      opacity: .5;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%); } }
  .catalog-icon {
    margin: 0 auto;
    line-height: 80px;
    padding: 15px 5px 5px;
    background: rgba(255, 255, 255, 0.9);
    min-height: 110px;
    max-width: 200px;
    img {
      width: auto;
      height: auto;
      max-height: 150px;
      max-width: 180px; } }
  .description {
    display: block;
    display: -webkit-box;
    max-width: 200px;
    margin: auto;
    font-size: 12px;
    line-height: 1.4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box; }
  // .badge-soon
  //   position: absolute
  //   z-index: 1
  //   background: #0075a8
  //   color: #fff
  //   padding: 3px 10px
  //   right: -13px
  //   top: 5px
  // .badge-soon:before
  //   content: ""
  //   border-style: solid
  //   border-color: #0075a8 #0075a8 #0075a8 transparent
  //   position: absolute
  //   top: 0
  //   left: -16px
  //   border-width: 13.5px
  //   height: 27px
  //   z-index: 2
  // .badge-soon:after
  //   content: ""
  //   border-color: #00558b transparent transparent #00558b
  //   border-style: solid
  //   border-width: 5px
  //   right: 0
  //   bottom: -10px
  //   display: block
  //   position: absolute
  // .badge-rancher-logo STRONG
  //   padding-left: 30px
  //   position: relative
  //   &:after
  //     content: ""
  //     font-family: rancher-icons
  //     font-size: 24px
  //     font-weight: 400
  //     position: absolute
  //     top: -8px
  //     left: 0
  // .badge-partner
  //   background-color: #a5c63b
  //   color: #000
  //   &:before
  //     border-color: #a5c63b #a5c63b #a5c63b transparent
  //   &:after
  //     border-color: #8eb021 transparent transparent #8eb021
  // .badge-soon
  //   background-color: #78c9cf
  //   color: #000
  //   &:before
  //     border-color: #78c9cf #78c9cf #78c9cf transparent
  //   &:after
  //     border-color: #389ea8 transparent transparent #389ea8
  // &.machine .catalog-icon
  //   min-height: 100px
  //   background-position: center
  //   background-repeat: no-repeat
 }  //   background-size: contain

.home {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  .icon {
    padding: 0.5rem; } }

.wrapper {
  width: 87.5%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px; }

.box {
  border: #ecf0f1 solid 0.5px;
  -webkit-box-shadow: 0 0 5px #ecf0f1;
  box-shadow: 0 0 5px #ecf0f1;
  border-radius: 6px;
  h2 {
    font-size: 2em;
    font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-weight: 300; }
  h3 {
    border-bottom: solid #ecf0f1 2px;
    padding-bottom: 10px;
    margin-bottom: 20px; }
  &:hover {
    // border: green solid 1px;
    border: solid #3498db; } }

/*box list */

.box-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }

.box-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0 0 10px;
  width: calc(100% * (1/4) - 10px - 10px); }

@media all and (max-width: 1024px) {
  .box-list-item {
    width: calc(100% * (1/2) - 10px - 10px); } }

@media all and (max-width: 640px) {
  .box-list-item {
    width: calc(100% - 10px - 10px); } }

.box-list-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  p {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
  .img-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 75px;
    img {
      max-height: 60px;
      max-width: 80%; } } }

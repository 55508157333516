$font-family-sans-serif: Muli, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-size-xs: .75rem;
$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.75rem;

$green: #00c793;
$green-dark: #00ba8a;
$green-darken: #007b5a;
$green-light: #f7fdfc;
$blue: #31acea;
$blue-darken: #1aa3e8;
$red: #ff4669;
$purple: #c53ed1;
$purple-darken: #ba2fc6;
$gray-dark: #2e313d;
$gray-800: $gray-dark;
$gray-dark-lighten: #373a49;
$gray: #61687f;
$gray-600: $gray;
$gray-darken: #565c71;
$gray-light: #8c99a5;
$gray-lighter: #d7dee4;
$gray-900: #61747f;
$alt-white-dark: #eff3f4;
$alt-white: #f3f7f9;
$black: #000000;
$white: #ffffff;
$white-transparent: rgba(255, 255, 255, .75);
$arrow-up: #ff4669;
$arrow-down: #00c793;
$arrow-right: #31acea;

$brand-primary: $green;
$primary: $green;

$sidebar-width: 400px;
$seachPanel-width: 480px;

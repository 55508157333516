@import "variables";
@import "bootstrap";
@import "topNav";
@import "home";
@import "box";
@import "sidebar";
@import "notifs";

.main-col {
  min-height: 100vh; }

.home {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  .icon {
    padding: 0.5rem; } }

.loader {
  color: $green; }

// FIXME : ménage / refacto

// body
//   font-size: $font-size-sm

//   &.no-scroll
//     overflow: hidden

// button, input, optgroup, select, textarea
//   font-family: $font-family-sans-serif

// [class^="icon-"]:before,
// [class*="icon-"]:before
//   vertical-align: middle

// .flex-grow
//   flex-grow: 1

// .main-col
//   background: $alt-white
//   background: var(--alt-white, $alt-white)
//   width: calc(100% - 250px)
//   min-height: 100vh

//   header,
//   .navbar,
//   section
//     background: $white
//     background: var(--white, $white)

//   section
//     border-radius: 3px
//     margin: 0 25px 50px
//     padding: 30px
//     box-shadow: 2px 2px 5px $gray-lighter
//     box-shadow: 2px 2px 5px var(--gray-lighter, $gray-lighter)

//     .section-header
//       margin: 0 -30px 30px
//       padding: 0 15px 25px
//       border-bottom: 1px solid $gray-lighter
//       border-bottom: 1px solid var(--gray-lighter, $gray-lighter)

//     h5
//       font-size: $font-size-lg
//       margin-bottom: 20px

// .bg-primary
//   background-color: $green !important
//   background-color: var(--green, $green) !important

// .popover
//   z-index: 9999

// .loader
//   color: $green
